import colors from "@assets/colors";
import SVGIcon from "@assets/svg";
import { useAuthContext } from "@src/AuthProvider";
import { Button01 } from "@src/components/button";
import { TextInput02 } from "@src/components/textinput";
import { useSignupService } from "@src/ducks/hooks";
import { generateMayaToken } from "@src/utils/api";
import { useFetchPost } from "@src/utils/api/hooks";
import { useInputHelper } from "@src/utils/inputs-helper";
import useSVGSizeHelper from "@src/utils/svg-size-helper";
import { useValidation } from "@src/utils/validation-helper";
import _ from "lodash";
import React from "react";
import { Text, View } from "react-native";
import { useSignupContext } from "../../provider";
import FreeTrial30Days from "../30days-trial-popup";
import useStyles from "./styles.css";


const initialState ={
  number: "",
  expMonth: "",
  expYear: "",
  cvc: ""
};

const MayaCard = () => {
  const styles = useStyles();
  const { onErrorMessage } = useAuthContext();
  const { loading, runCallbackRequest } = useFetchPost();
  const { state, subscriptionPlan } = useSignupContext();
  const {state: card, onDispatch} = useInputHelper(initialState);
  const {errorInput, checkValidation, onSetError} = useValidation();
  const { failed, isLoading, onSignupWithPromo } = useSignupService();
  const [visible30daysTrial, setVisible30DaysTrial] = React.useState(false);
  const size = useSVGSizeHelper({
    LARGE: { width: 108, height: 32},
    DEFAULT: { width: 144, height: 42},
  });

  const onChangeText = (type: string) => (value: string) => {
    if(type === "number"){
      const getMaskedValue = Array.from(value.replaceAll(/\D/g, "")
        .matchAll(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/g))[0].slice(1, 5).join(" ").trim();

      onDispatch(type)(getMaskedValue);
    }else{
      onDispatch(type)(value.replace(/[^0-9]/g, ""));
    }
    onSetError({});
  };

  const handleProceedPayment = () => {
    setVisible30DaysTrial(false);
    onSignupWithPromo({
      ...state,
      id: subscriptionPlan.id,
      name: subscriptionPlan.name,
      cost: subscriptionPlan.cost,
      card: {...card, number: card.number.replace(/\s/g, "")}
    });
  }

  const handleSubmit = async () => {
    if(_.isEmpty(checkValidation(initialState, card, " "))){
      runCallbackRequest(
        () => generateMayaToken({card: {...card, number: card.number.replace(/\s/g, "")} }),
        () => {
          if(state.promoCode?.toUpperCase() === "SSV2FREE30"){
            setVisible30DaysTrial(true);
            return;
          }

          handleProceedPayment();
        },
        (error: any) => {
          if(error.message){
            const message = !_.isEmpty(error.parameters) ? error.parameters[0].description : error.message
            onErrorMessage(message);
          }
        }
      );
    }
  };



  React.useEffect(() => {
    if(failed?.message){
      onErrorMessage(failed.message);
    }
  },[failed]);


  return (
    <>
    <View style={styles.container}>
      <View style={styles.v_flex_row}>
        <View style={styles.logoMaya} >
          <SVGIcon name="maya" {...size} />
        </View>
        <Text style={styles.t_confirm}>Pay using your Credit, Debit, or Prepaid Card</Text>
      </View>
      <View style={styles.v_divider}/>
      <View style={styles.v_flex_row}>
        <TextInput02 label="Card Number"
          maxLength={19}
          style={[styles.ti_style, styles.ti_card]}
          placeholder="4324324-45345"
          keyboardType="number-pad"
          value={card.number}
          error={errorInput.number}
          borderStyle={styles.ti_borderStyle}
          labelStyle={styles.labelStyle}
          onChangeText={onChangeText("number")}
        />
        <TextInput02 label="Expiration Month"
          numberOfLines={1}
          placeholder="eg. 06"
          maxLength={2}
          style={styles.ti_style}
          keyboardType="number-pad"
          value={card.expMonth}
          error={errorInput.expMonth}
          labelStyle={styles.labelStyle}
          borderStyle={styles.ti_borderStyle}
          onChangeText={onChangeText("expMonth")}
        />
        <TextInput02 label="Expiration Year"
          numberOfLines={1}
          placeholder="eg. 2022"
          maxLength={4}
          style={[styles.ti_style]}
          keyboardType="number-pad"
          value={card.expYear}
          error={errorInput.expYear}
          labelStyle={styles.labelStyle}
          borderStyle={styles.ti_borderStyle}
          onChangeText={onChangeText("expYear")}
        />
        <TextInput02 label="Security Code"
          numberOfLines={1}
          maxLength={4}
          placeholder="CVC / CVV"
          style={styles.ti_style}
          keyboardType="number-pad"
          value={card.cvc}
          error={errorInput.cvc}
          labelStyle={styles.labelStyle}
          borderStyle={styles.ti_borderStyle}
          onChangeText={onChangeText("cvc")}
        />
      </View>
    </View>
    <Button01
      label="Pay"
      isLoading={isLoading || loading}
      color={colors.white}
      onPress={handleSubmit}
      style={styles.btn_proceed}
      labelStyle={styles.lbl_proceed}
      />
    <FreeTrial30Days 
      visible={visible30daysTrial}
      onCancel={() => setVisible30DaysTrial(false)}
      onProceed={handleProceedPayment} />
    <View style={styles.v_height} />
    </>
  );
};

export default MayaCard;
