import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    v_signup: {
      width: "100%",
      paddingHorizontal: "20%",
      marginTop: 0,
    },
    promoAlert: {
      marginTop: 43,
      width: "75%",
      height: 59,
      borderWidth: 2, 
      borderColor: colors.blue,
      borderRadius: 8,
      backgroundColor: "#DCE2F1",
      justifyContent:"center",
    },
    promoAlertText:{
      fontFamily: "DMSans", 
      fontWeight: "700",
      textAlign:"center",
      fontSize: 18,
      color: colors.blue
    },
    v_field: { marginTop: 0 },
    v_flex1: { flex: 1 },
    btn_signup: { marginTop: 30, backgroundColor: colors.blue, marginHorizontal: "12%" },
    lbl_signup: { fontFamily: "Montserrat-Bold" },
    v_height: { height: 50 },
  },
  {
    [DEVICE_SIZES.LG]: {
      v_signup: { marginTop: 80 },
      v_flex1: { marginTop: 17, },
    
      lbl_signup: { fontSize: 10.5, lineHeight: 12 },
     
      btn_signup: { marginTop: 20, borderRadius: 6, height: 43 },
    },
    [DEVICE_SIZES.MD]: {
      v_flex1: { marginTop: 20 },
     
      v_signup: {
        flex: 1,
        minWidth: "100%",
        maxWidth: "100%",
        marginTop: 100,
        alignSelf: "center",
        paddingVertical: 0,
        paddingHorizontal: "12%",
        justifyContent: "flex-start",
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      promoAlert: {
        width: "95%",
      },
      promoAlertText:{
        fontSize: 14,
      },
      v_signup: {
        flex: 1,
        paddingBottom: 50,
        borderRadius: 0,
        minWidth: "100%",
        maxWidth: "100%",
        paddingVertical: 0,
        paddingHorizontal: 20,
        justifyContent: "flex-start",
      },
      btn_signup: { marginTop: 32 },
    },
  }
);

export default useStyles;
