import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    os_container: {
      width: "100%",
      marginTop: 20,
      paddingHorizontal: "20%",
      backgroundColor: colors.white,
    },
    t_order_summary: {
      color: colors.black3,
      fontFamily: "DMSans-Bold",
      fontSize: 18,
      lineHeight: 23,
    },
    t_os_note: {
      marginTop: 15,
      color: colors.gray9,
      fontFamily: "DMSans",
      fontSize: 14,
      lineHeight: 18,
    },
    v_divider: {
      height: 1,
      marginTop: 26,
      marginBottom: 20,
      backgroundColor: colors.gray10,
    },
    v_divider2: {
      height: 1,
      marginTop: 20,
      marginBottom: 20,
      backgroundColor: colors.gray10,
    },
    v_height: { height: 50 },
    promoAlert: {
      marginTop: 43,
      width: "75%",
      height: 59,
      borderWidth: 2, 
      borderColor: colors.blue,
      borderRadius: 8,
      backgroundColor: "#DCE2F1",
      justifyContent:"center",
    },
    promoAlertText:{
      fontFamily: "DMSans", 
      fontWeight: "700",
      textAlign:"center",
      fontSize: 18,
      color: colors.blue
    },
    v_divider3: {
      height: 1,
      marginTop: 88,
      marginBottom: 25,
      backgroundColor: colors.gray10,
    },
    t_package_details: {
      color: colors.black3,
      fontFamily: "DMSans",
      fontSize: 14,
      lineHeight: 18,
    },
    t_document_title: {
      color: colors.black3,
      fontFamily: "Lato",
      fontSize: 16,
      lineHeight: 20,
    },
    t_add_margin: { marginTop: 7 },
    v_item_rows: {flexDirection: "row", alignItems: "center", justifyContent: "space-between"},
    add_row_margin_top: { marginTop: 20 },
    t_font_bold: { fontFamily: "Lato-Bold" },
    t_total: {
      color: colors.black3,
      fontFamily: "DMSans-Bold",
      fontSize: 16,
      lineHeight: 20,
    },
    t_change_plan: { 
      fontFamily: "DMSans-Bold",
      fontSize: 16,
      lineHeight: 21,
      marginTop: 30,
      color: colors.blue,
      textAlign: "center",
    },
    btn_proceed: { marginTop: 20, height: 50, backgroundColor: colors.blue, marginHorizontal: "12%" },
    lbl_proceed: { fontFamily: "DMSans-Bold", fontSize: 16, lineHeight: 16, color: "white" },
  },
  {
    [DEVICE_SIZES.LG]: {
      os_container: {
        marginTop: 35,
      },
      t_order_summary: {
        fontSize: 16,
        lineHeight: 19,
      },
      t_os_note: {
        marginTop: 10,
        fontSize: 10.5,
        lineHeight: 14,
      },
      v_divider: {
        height: 0.75,
        marginTop: 18,
        marginBottom: 15,
      },
      v_divider2: {
        height: 0.75,
        marginTop: 15,
        marginBottom: 15,
      },
      v_divider3: {
        height: 0.75,
        marginTop: 60,
        marginBottom: 19,
      },
      t_package_details: {
        fontSize: 11,
        lineHeight: 15,
      },
      t_document_title: {
        fontSize: 12,
        lineHeight: 16,
      },
      t_total: {
        fontSize: 12,
        lineHeight: 16,
      },
      add_row_margin_top: { marginTop: 10 },
      t_change_plan: { 
        fontSize: 12,
        lineHeight: 16,
        marginTop: 23,
      },
      btn_proceed: { marginTop: 45, height: 40, backgroundColor: colors.blue, borderRadius: 6 },
      lbl_proceed: { fontSize: 12, lineHeight: 12, color: "white" },
    },
    [DEVICE_SIZES.MD]: {
      os_container: {
        paddingHorizontal: "12%",
      }
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      promoAlert: {
        width: "95%",
      },
      promoAlertText:{
        fontSize: 14,
      },
      os_container: {
        flex: 1,
        width: "100%",
        marginTop: 60,
        borderRadius: 0,
        paddingHorizontal: 20,
      },
      add_row_margin_top: { marginTop: 10 },
    },
  }
);

export default useStyles;
